import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

//Components
import About from '../../components/Emprendimientos/about-developments'
import BannerTop from "../../components/Global/Modules/MediaPush/BannerTop";

const IndexPage = (props) => {
  const {tag} = props
  return(
    <Layout>
      <Seo title="Emprendimientos" />
      <BannerTop page="emprendimientos"/>
      <About tag={tag} />
    </Layout>
  )
}

export default IndexPage
